import styled from '@emotion/styled';

import Link from '@app/components/Link';
import { NotebookTitleFont } from '@app/components/notebook/NotebookStyled';

type NotebookLogoTitleProps = {
  subTitle?: string;
  subTitleColor?: string;
};

const NotebookLogoTitle = ({
  subTitle = '',
  subTitleColor = 'black',
}: NotebookLogoTitleProps) => (
  <LogoTitleLink href="/notebook">
    <LogoTitle>
      <NotebookTitleFont color="#9B9B9B">Notebook</NotebookTitleFont>
      {subTitle && (
        <>
          {' '}
          <LogoTitleSubTitle subTitleColor={subTitleColor}>
            {subTitle}
          </LogoTitleSubTitle>
        </>
      )}
    </LogoTitle>
  </LogoTitleLink>
);

const LogoTitleLink = styled(Link)`
  display: inline-block;
  padding: 29px 0;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;

const LogoTitle = styled.h2`
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
`;

const LogoTitleSubTitle = styled.span<{ subTitleColor: string }>`
  color: ${props => props.subTitleColor};
  display: inline-block;
  ${props => props.theme.font.unantialias}
  font-family: ${props => props.theme.font.body};
`;

export default NotebookLogoTitle;
